.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-View {
  background-color: #f5efef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
}

.App-NdtHeader {
  font-size: 30px;
  color: #2574a9;
  font-weight: bold;
  margin: 1vmin;
}

table.table-bordered {
  /*border: 2px solid #616a72;*/
  border: 2px solid black;
  margin-top: 20px;
}
table.table-bordered > thead > tr > th {
  border: 1px solid black;
}
table.table-bordered > tbody > tr > td {
  border: 1px solid black;
}
table.table-bordered > tbody > tr > th {
  /*border: 1px solid white;*/
}

th{
  background-color:teal !important;
  color:white !important;
  text-align:center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

tr:nth-child(odd){
  background-color: #E5E5E5 !important;
}

tr:nth-child(even){
  background-color: #ffffff !important;
}

th{
 background-color:#17a2b8 !important;
 color:black !important;
}



/* START CHECKBOX*/
input[type=checkbox], input[type=radio] {
  font-size:4px!important;
}

.btn-primary, .page-item.active .page-link {
  background-color:#2574a9 !important;
}

